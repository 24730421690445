.grid-icon {
  position: relative;

  .coming-soon-badge {
    position: absolute;
    top: -11px;
    left: calc(50% + 10px);
    width: 45px;
    font-size: 8px;
    line-height: 8.8px;
    background-color: var(--color-badge);
    color: black;
    text-align: center;
    border-radius: 50px;
    border: 3px solid var(--color-border-coming-soon);
    padding: 4px 4px;
    font-weight: var(--font-weight-bolder);
  }

  .icon-button {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding: 4px 8px;
    border: 1px solid var(--color-icon-bg);
    background-color: var(--color-icon-border);

    &[href=''] {
      pointer-events: none;
    }

    &::before {
      padding-right: calc(var(--gutter) / 2);
      font-size: 20px;
    }

    .icon-label {
      font-weight: var(--font-weight-bold);
      word-wrap: break-word;
      font-size: 12px;
      color: var(--color-heading-primary);
    }

    &.no-icon {
      text-align: center;
      border-radius: 50px;
      border: 3px solid var(--black);
      background: var(--color-badge);
      padding: 4px;
      color: var(--black);
    }

    &.secondary {
      color: var(--icon-color);
      flex-direction: column;
      padding: 0;
      background: none;
      border: none;

      &::before {
        font-size: 24px;
        height: 48px;
        width: 48px;
        flex: 1 0 48px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: var(--color-icon-secondary-bg);
      }

      .icon-label {
        text-align: center;
        padding-top: calc(var(--gutter) / 2);
        color: var(--color-heading-primary);
      }

      .coming-soon-badge {
        display: block;
      }

      &.icon-games {
        color: var(--color-icon-games);
      }

      &.icon-offers {
        color: var(--color-icon-offers);
      }

      &.icon-benefits {
        color: var(--color-icon-benefits);
      }
    }
  }
}
