.restaurant-parent > a[href=''] {
  pointer-events: none;
}

.restaurant-item {
  position: relative;
  color: var(--white);
  background-size: cover;
  background-position: center 70%;
  min-height: 133px;
  aspect-ratio: 382/133;
  max-width: 100%;
  display: flex;
  /* background-blend-mode: darken; */
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  margin-bottom: calc(var(--gutter) / 2);

  & &_title_link {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &_text_content {
    flex: 1 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-image: var(--color-experience-bottom-gradient);
    padding: 0 12px var(--gutter) var(--gutter);
    border-radius: 8px;

    * {
      color: inherit;
    }

    .coming-soon {
      display: flex;
      align-items: center;
      background-color: var(--color-primary);
      color: var(--white);
      text-transform: uppercase;
      position: absolute;
      right: 0;
      top: calc(var(--gutter) / 2);
      padding: calc(var(--gutter) / 4) calc(var(--gutter) / 2);

      i {
        line-height: 1;
        margin-right: 4px;
      }
    }

    .icon-ribbon:before {
      position: absolute;
      left: 1px;
      transform: translateX(-100%);
      top: 0;
      bottom: 0;
      font-size: calc((12px * 1.4) + 8px);
      color: var(--color-primary);
    }

    a {
      font-size: 14px;
      line-height: 23px;
      white-space: nowrap;
      display: flex;
      align-items: baseline;
      justify-content: center;
      height: 23px;
      min-height: 23px;
    }

    p.restaurant-item_seconday_title {
      line-height: 16px;
      height: 17px;
      font-weight: var(--font-weight-normal);
      font-family: var(--primary-font-family);
    }
  }

  .icon-arrow-right::after {
    margin-left: 8px;
  }
}
