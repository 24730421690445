/* icon variable and dynamic classes */
$icon: (
  hotel: '\e904',
  experiences: '\e905',
  arrow-head: '\e909',
  diamond: '\e90a',
  home: '\e90b',
  home-active: '\e90c',
  more: '\e90e',
  delete: '\e911',
  arrow-right: '\e91b',
  casino-chip: '\e91c',
  wallet: '\e91d',
  score: '\e91e',
  tier: '\e91f',
  logout: '\e920',
  phone: '\e926',
  phone-email: '\e92a',
  email: '\e92c',
  about-bally: '\e928',
  profile: '\e919',
  faq: '\e929',
  cookie: '\e923',
  privacy: '\e922',
  vendors: '\e92d',
  vendor-licensing: '\e921',
  plus: '\e908',
  minus: '\e915',
  ribbon: '\e902',
  user-note: '\e903',
  drink: '\e906',
  food: '\e907',
  zipcode: '\e90d',
  star: '\e917',
  calendar: '\e914',
  construction: '\e92e',
  career: '\e92b',
  terms: '\e934',
  timer: '\e925',
  info: '\e901',
  transaction: '\e90f',
  benefits: '\e91a',
  offers: '\e933',
  dob: '\e913',
  id-number: '\e918',
  location: '\e924',
  address: '\e912',
  games: '\e935',
  expiry-date: '\e916',
  faceid: '\e930',
  biometric: '\e93b',
  spa: '\e937',
  promotions: '\e939',
  theme: '\e927',
  bluetooth: '\e931',
  card-in: '\e932',
  card-out: '\e936',
  tick: '\e910',
  logo: '\e93a',
  preview: '\e93d',
  link: '\e93e'
);

@each $k, $v in $icon {
  .icon-#{$k}::before {
    content: '#{$v}';
  }
}
