.page-more {
  .more-logo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-top: calc(var(--gutter) / 2);
    padding: 6px 0;

    img {
      height: 68px;
      min-height: 68px;
    }

    p {
      margin-top: 8px;
      text-align: center;
      color: var(--color-heading);
    }
  }

  .more-heading {
    text-transform: uppercase;
  }

  .more-gap {
    margin-bottom: var(--secondary-gutter);
  }

  /* app version css */
  .app-version-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    .app-version-logo {
      display: flex;
      gap: 7px;
      flex-direction: row;
      align-items: center;
    }

    .more-bootom-logo {
      width: 235px;
      min-height: 46px;
    }

    .app-version {
      font-family: var(--primary-font-family);
      font-weight: var(--font-weight-normal);
      line-height: 16px;
      margin-top: 8px;
    }
  }

  .disclaimer-content-section {
    margin-bottom: 35px;
  }
}
