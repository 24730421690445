.email-input {
  font-size: 14px;
  line-height: 1.4;
  border: 1px solid var(--white);
  border-radius: 8px;
  padding: calc(var(--gutter) / 2) var(--gutter);
  text-transform: uppercase;
  color: var(--white);
  background-color: transparent;
  width: 100%;
  display: block;
  box-sizing: border-box;
}
