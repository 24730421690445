.dark-mode {
  /* Font variable  */
  --body-font-size: 16px;

  /* Font family */
  --seconday-font-family: 'Bally Thrill', 'Helvetica Neue';
  --primary-font-family: 'Inter', sans-serif;

  /* Font Weigth */
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-weight-bolder: 600;
  --font-weight-boldest: 700;

  /* COLORS */
  --color-heading-primary: #acaaaf;
  --color-heading: #e4e2e6;
  --color-body: #acaaaf;

  --black: #0c0313;
  --icon: #c1bdd0;
  --gray: #c1bdd0;
  --color-grid-button: #343438;
  --color-badge: #ffcb4f;
  --white: #ffffff;
  --shadow: rgba(0, 0, 0, 0.25);
  --header-primary-color: 'transparent';
  // linear-gradient(110.97deg, var(--color-primary) 50%, #af0000 100%);
  --listing-background-color: #1b1b1f;
  --listing-background-color-secondary: #131316;
  --primary-border-color: #303033;
  --disabled-btn-color: #908499;
  --primary-icon-backgrond-color: #59595a;
  --color-input-border: #cccccc;
  --color-input: #220000;
  --wave-color: rgba(236, 0, 0, 0.3);
  --color-popup-body: rgba(251, 248, 253, 0.7);
  --color-popup-icon-bg: linear-gradient(180deg, #3e3e46 0%, #242429 100%);
  --scan-area-color: #00ff0a;
  --color-modal-overlay: rgba(0, 0, 0, 0.5);
  --color-light-pink: #ffe9e9;
  --color-parking-discount: #15924a;
  --color-blur: #00000050;
  --color-border: #46464a;
  --color-blur: rgba(0, 0, 0, 0.4);
  --color-error: #ff897d;
  --color-gray-bg: rgba(0, 0, 0, 0.5);
  --color-button-negative: #ffb4a8;
  --background-notification: #690000;
  --color-pro: #feee00;
  --color-star: #84c450;
  --color-superstar: #00b6dd;
  --color-legend: #ef0502;
  --color-register-gradient-first: #3e285f;
  --color-register-gradient-second: #200833;
  --color-text: --color-body;
  --color-timer-bg: #efebed;
  --color-icon-games: #35d04e;
  --color-icon-offers: #feaa01;
  --color-icon-benefits: #35b4d0;
  --color-btn-gradient-primary: #29292e;
  --color-btn-gradient-secondary: #565661;
  --color-btn-border-secondary: #a08c89;
  --color-warning-icon: #f9b208;
  --color-blue: #358eff;
  --color-linear-line: linear-gradient(270deg, #c7c6ca 0.44%, rgba(199, 198, 202, 0) 100%);
  --color-rewards-heading: #e4e2e6;
  --color-rewards-white: #acaaaf;
  --color-primary: #ec0000;
  --color-input-focus: #c00100;
  --color-icon-notification: #ffdad4;
  --color-profile-bg: #0c0313;
  --color-profile-bg-primary: #1b1b1f;
  --color-pofile-border: rgba(255, 255, 255, 0.2);
  --box-profile-shadow: 0px;
  --color-border-coming-soon: #0c0313;
  --color-input-bg: #1f1f23;
  --color-icon-secondary-bg: linear-gradient(
    110deg,
    var(--color-btn-gradient-primary),
    var(--color-btn-gradient-secondary) 500px,
    var(--color-btn-gradient-primary) 800px
  );
  --color-bg-switch: #343438;
  --color-border-switch: #919094;
  --color-switch-active: #4a934a;
  --color-switch-bg: #cceecc;
  --color-popup-bg: #1b1b1f;
  --color-popup-secondary-bg: #292a2d;
  --color-experience-bottom-gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.82) 73.44%
  );
  --color-app-bg: #000000;
  --color-profile-text: #919094;
  --color-footer-bg: #000000;
  --color-popup-icon-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  --skelton-loader: linear-gradient(
    90deg,
    rgba(128, 89, 58, 0) 0,
    rgba(128, 89, 58, 0.4) 40%,
    rgba(128, 89, 58, 0.2) 60%,
    rgba(128, 89, 58, 0)
  );
  --label-color: #e4e2e6;
  /* CARDLESS */
  --color-balance-border: #39393c;
  --color-balance-bg: rgba(0, 0, 0, 0.5);
  --color-connecting: #0066ff;
  --color-connecting-stroke: rgba(255, 255, 255, 0.09);
  --color-connected-stroke: rgba(73, 219, 131, 0.3);
  --color-conneting-bg: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 109, 219, 0.25) 0%,
    rgba(73, 109, 219, 0) 100%
  );
  --color-disconnecting: #ef0502;
  --color-disconnecting-bg: #690000;
  --color-connected-bg: #053005;
  --color-connected: rgba(73, 219, 131, 0.3);
  --color-check-bg: #5d9252;
  --color-cardin: linear-gradient(135deg, #30f272 0%, #00831d 100%);
  --color-cardout: linear-gradient(135deg, #ff6c6c 0%, #a91a1a 100%),
    linear-gradient(135deg, #0083fd 0%, #1a28a9 100%),
    linear-gradient(135deg, #30f2c4 0%, #047b7b 100%);
  --color-connected-transparent-bg: rgba(0, 0, 0, 0.2);
  --color-tier-grdient: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 109, 219, 0.25) 0%,
    rgba(73, 109, 219, 0) 100%
  );
  --color-black: #000000;
  --color-text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15);
  --noise-bg-gradient: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.07) 0%,
    rgba(255, 255, 255, 0.14) 4.75%,
    rgba(255, 255, 255, 0.06) 14.25%,
    rgba(255, 255, 255, 0.07) 21.36%,
    rgba(255, 255, 255, 0.1) 28.65%,
    rgba(255, 255, 255, 0.13) 36.57%,
    rgba(255, 255, 255, 0.17) 47.96%,
    rgba(255, 255, 255, 0.13) 59.47%,
    rgba(255, 255, 255, 0.03) 79.95%,
    rgba(255, 255, 255, 0) 100%
  );
  --color-placeholder: #919094;
  --color-icon-bg: #39393c;
  --color-icon-border: #292a2d;
  --color-footer-border: #303034;
  --color-lobby-mid-box-bg: #0d0e11;
  --color-lobby-mid-box-border: #303034;
  --color-input-bg-disabled: #0d0e11;
  --color-green-primary: #4a934a;
  --color-red-bg-primary: #410000;
  --offers-bg: linear-gradient(259deg, #ef0502 0%, #690000 100%);
  --color-input-disabled: #919094;

  /* Size and spacing */
  --container-max-width: 450px;
  --gutter: 16px;
  --secondary-gutter: 24px;
  --header-height: 64px;
  --secondary-header-height: 110px;
  --footer-height: 70px;
  --notification-height: 58px;

  /* BREAKPOINTS */
  --mobile-max: 480px;
  --tablet-min: 768px;
  --tablet-max: 991px;
  --notebook-min: 992px;
  --notebook-max: 1023px;
}

.light-mode {
  /* Font variable  */
  --body-font-size: 16px;

  /* Font family */
  --seconday-font-family: 'Bally Thrill', 'Helvetica Neue';
  --primary-font-family: 'Inter', sans-serif;

  /* Font Weigth */
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-weight-bolder: 600;
  --font-weight-boldest: 700;

  /* COLORS */
  --color-heading-primary: #303034;
  --color-heading: #303034;
  --color-body: #77767a;

  --black: #0c0313;
  --icon: #77767a;
  --gray: #c1bdd0;
  --color-grid-button: #343438;
  --color-badge: #ffcb4f;
  --white: #ffffff;
  --shadow: rgba(0, 0, 0, 0.25);
  --header-primary-color: 'transparent';
  // linear-gradient(110.97deg, var(--color-primary) 50%, #af0000 100%);
  --listing-background-color: #f2f0f4;
  --listing-background-color-secondary: #fefbff;
  --primary-border-color: #303033;
  --disabled-btn-color: #908499;
  --primary-icon-backgrond-color: #59595a;
  --color-input-border: #cccccc;
  --color-input: #220000;
  --wave-color: rgba(236, 0, 0, 0.3);
  --color-popup-body: rgba(251, 248, 253, 0.7);
  --color-popup-icon-bg: linear-gradient(180deg, #edebf0 0%, #d7d5db 100%);
  --scan-area-color: #00ff0a;
  --color-modal-overlay: rgba(0, 0, 0, 0.5);
  --color-light-pink: #ffe9e9;
  --color-parking-discount: #15924a;
  --color-blur: #00000050;
  --color-border: #e4e2e6;
  --color-blur: rgba(0, 0, 0, 0.4);
  --color-error: #c00100;
  --color-gray-bg: rgba(0, 0, 0, 0.06);
  --color-button-negative: #ef0502;
  --color-input-focus: #c00100;
  --background-notification: #ffdad4;
  --color-pro: #feee00;
  --color-star: #84c450;
  --color-superstar: #00b6dd;
  --color-legend: #ef0502;
  --color-register-gradient-first: #3e285f;
  --color-register-gradient-second: #200833;
  --color-text: --color-body;
  --color-timer-bg: #efebed;
  --color-icon-games: #35d04e;
  --color-icon-offers: #feaa01;
  --color-icon-benefits: #35b4d0;
  --color-btn-gradient-primary: #29292e;
  --color-btn-gradient-secondary: #565661;
  --color-btn-border-secondary: #77767a;
  --color-warning-icon: #f9b208;
  --color-blue: #358eff;
  --color-linear-line: linear-gradient(270deg, #c7c6ca 0.44%, rgba(199, 198, 202, 0) 100%);
  --color-rewards-heading: #e4e2e6;
  --color-rewards-white: #acaaaf;
  --color-primary: #ef0502;
  --color-input-focus: #ef0502;
  --color-icon-notification: #ef0502;
  --color-profile-bg: #f2f0f4;
  --color-profile-bg-primary: #f2f0f4;
  --color-pofile-border: #ffffff;
  --box-profile-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --color-border-coming-soon: #ede7d5;
  --color-input-bg: #f2f0f4;
  --color-icon-secondary-bg: #ffffff;
  --color-bg-switch: #e4e2e6;
  --color-border-switch: #acaaaf;
  --color-switch-active: #ffffff;
  --color-switch-bg: #4a934a;
  --color-popup-bg: #f5f3f7;
  --color-popup-secondary-bg: #e9e7ec;
  --color-experience-bottom-gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.41) 73.44%
  );
  --color-app-bg: #ffffff;
  --color-profile-text: #77767a;
  --color-footer-bg: #ffffff;
  --color-popup-icon-shadow: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));
  --skelton-loader: linear-gradient(
    90deg,
    #fefbff 0%,
    #f2f0f4 32%,
    #e4e2e6 50%,
    #f2f0f4 70%,
    #fefbff 100%
  );
  --label-color: #46464a;
  /* CARDLESS */
  --color-balance-border: rgba(0, 0, 0, 0.06);
  --color-balance-bg: rgba(0, 0, 0, 0.06);
  --color-connecting: #0066ff;
  --color-connecting-stroke: rgba(255, 255, 255, 0.09);
  --color-connected-stroke: rgba(73, 219, 131, 0.3);
  --color-conneting-bg: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 109, 219, 0.25) 0%,
    rgba(73, 109, 219, 0) 100%
  );
  --color-disconnecting: #ef0502;
  --color-disconnecting-bg: #690000;
  --color-connected-bg: #053005;
  --color-connected: rgba(73, 219, 131, 0.3);
  --color-check-bg: #5d9252;
  --color-cardin: linear-gradient(135deg, #30f272 0%, #00831d 100%);
  --color-cardout: linear-gradient(135deg, #ff6c6c 0%, #a91a1a 100%),
    linear-gradient(135deg, #0083fd 0%, #1a28a9 100%),
    linear-gradient(135deg, #30f2c4 0%, #047b7b 100%);
  --color-connected-transparent-bg: rgba(0, 0, 0, 0.2);
  --color-tier-grdient: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 109, 219, 0.25) 0%,
    rgba(73, 109, 219, 0) 100%
  );
  --color-black: #000000;
  --color-text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15);
  --noise-bg-gradient: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.07) 0%,
    rgba(255, 255, 255, 0.14) 4.75%,
    rgba(255, 255, 255, 0.06) 14.25%,
    rgba(255, 255, 255, 0.07) 21.36%,
    rgba(255, 255, 255, 0.1) 28.65%,
    rgba(255, 255, 255, 0.13) 36.57%,
    rgba(255, 255, 255, 0.17) 47.96%,
    rgba(255, 255, 255, 0.13) 59.47%,
    rgba(255, 255, 255, 0.03) 79.95%,
    rgba(255, 255, 255, 0) 100%
  );
  --color-placeholder: #77767a;
  --color-icon-bg: #f2f0f4;
  --color-icon-border: #e4e2e6;
  --color-footer-border: #e4e2e6;
  --color-lobby-mid-box-bg: rgba(0, 0, 0, 0.06);
  --color-lobby-mid-box-border: #e4e2e6;
  --color-input-bg-disabled: #e4e2e6;
  --color-green-primary: #4a934a;
  --color-red-bg-primary: #ffedea;
  --offers-bg: linear-gradient(259deg, #ef0502 0%, #690000 100%);
  --color-input-disabled: #77767a;

  /* Size and spacing */
  --container-max-width: 450px;
  --gutter: 16px;
  --secondary-gutter: 24px;
  --header-height: 64px;
  --secondary-header-height: 110px;
  --footer-height: 70px;
  --notification-height: 58px;

  /* BREAKPOINTS */
  --mobile-max: 480px;
  --tablet-min: 768px;
  --tablet-max: 991px;
  --notebook-min: 992px;
  --notebook-max: 1023px;
}
