.ballys-app {
  max-width: var(--container-max-width);
  max-height: 100vh;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;

  > * {
    flex: 1 0;
  }

  > main {
    max-height: calc(100vh - var(--footer-height) - var(--header-height));
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &.no-header {
    > main {
      max-height: calc(100vh - var(--footer-height));
      padding-top: var(--gutter);
    }
  }

  &.no-footer {
    > main {
      max-height: calc(100vh - var(--header-height));
      padding-bottom: var(--gutter);
    }
  }

  &.no-header.no-footer > main {
    max-height: 100vh;
  }

  &.notification-visible {
    > main {
      padding-bottom: var(--notification-height);
    }
  }

  > .header_tertiary {
    & + main {
      max-height: 100vh;
    }
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

[class*='icon-']::before,
[class*='icon-']::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
