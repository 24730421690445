.radio-box {
  padding: 8px 0px;
  display: flex;
  align-items: center;

  .radio-option {
    font-size: 14px;
    color: var(--color-heading);
  }

  .radio-button {
    width: 16px;
    height: 16px;
    margin: 0px;
    margin-right: var(--gutter);
    margin-left: 1px;
    border: 3px solid var(--color-app-bg);
    box-shadow: 0 0 0 2px var(--color-heading);
    appearance: none;
    border-radius: 50%;
    background-color: var(--color-app-bg);
    transition: all ease-in 0.2s;

    &:checked {
      box-shadow: 0 0 0 2px var(--color-primary);
      background-color: var(--color-primary);
    }
  }
}
