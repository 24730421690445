.login-choose-option {
  .option-container {
    margin-top: 32px;
    display: flex;
    height: 208px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--color-footer-border);
    background: var(--listing-background-color-secondary);
    position: relative;
    &:last-child {
      margin-bottom: 117px;
    }
    &.active {
      background-color: var(--color-red-bg-primary);
      border: 1px solid var(--color-legend);
    }
    .select {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 16px;
      right: 16px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--color-border-switch);
      &.icon-tick {
        border: 0px;
        color: var(--color-red-bg-primary);
        background-color: var(--color-green-primary);
      }
    }
    .icon-preview {
      font-size: 50px;
    }
    .icon-link {
      font-size: 50px;
    }
    .option-text {
      color: var(--color-heading);
    }
  }
  .input-btn {
    position: absolute;
    left: var(--gutter);
    right: var(--gutter);
    bottom: var(--gutter);
    width: auto;
  }
}
