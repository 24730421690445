.input-container {
  &.error {
    border: 1px solid var(--color-error);
  }

  text-align: center;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  flex-grow: 1;
  padding: 12px var(--gutter);
  background-color: var(--color-input-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;

  &:disabled {
    background-color: var(--color-input-bg-disabled);
  }
  .form-control {
    border: none;
    background-color: var(--color-input-bg);
    color: var(--color-heading);
    width: 100%;
    &::placeholder {
      color: var(--color-body);
    }
  }

  select {
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;

    option {
      background-color: var(--color-input-bg);
    }
  }

  .PhoneSubmit-selection-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 72px;
    flex-grow: 0;
    position: relative;
    margin-right: var(--gutter);

    .PhoneSubmit-selected-flag {
      height: 16px;
      width: 24px;
      flex: 1 0 24px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: var(--light-grey);
      display: block;
      border-radius: 3px;
    }

    .PhoneSubmit-selected-code {
      pointer-events: none;
      position: relative;
      display: flex;
      align-items: center;

      .PhoneSubmit-selected-country-code {
        padding: 0 8px;
      }

      .icon-arrow-head {
        font-size: 10px;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }
  }
}
