.offer-button {
  background: var(--offers-bg);
  height: 56px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: var(--seconday-font-family);
  font-style: normal;
  font-weight: var(--font-weight-bolder);
  text-transform: uppercase;
  color: var(--white);
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 16px;
  .icon-arrow-head {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row-reverse;
  }
  &.disabled {
    pointer-events: none;
    background-color: var(--disabled-btn-color);
  }
  img {
    display: inline-block;
    width: 50px;
    height: 39px;
    background-position: center;
    background-size: cover;
  }
}
