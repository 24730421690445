.property-popup {
  .modal-content-property {
    position: absolute;
    bottom: 0px;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    border-radius: 28px 28px 0px 0px;
    padding-bottom: 5px;
    background-color: var(--color-popup-bg);
  }

  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
  }

  .property-heading {
    font-weight: var(--font-weight-boldest);
    color: var(--color-heading);
    font-family: var(--seconday-font-family);
    line-height: 120%;
    padding: 20px 0px;
    font-size: 24px;
  }

  .cross-button {
    border: none;
    background: none;
    display: flex;
    color: var(--color-rewards-white);
    font-size: var(--body-font-size);

    &::before {
      transform: rotate(45deg);
    }
  }

  .property-list {
    list-style-type: none;
    border-radius: 16px;
    margin: 8px 16px;
    font-size: 14px;
    color: var(--color-body);
    border: 1px solid var(--color-lobby-mid-box-border);
    background: var(--listing-background-color-secondary);
    &:last-child {
      margin-bottom: 40px;
    }
  }

  .property-list-item {
    display: flex;
    padding: var(--gutter);
    justify-content: space-between;
    align-items: center;
    .property-details {
      display: flex;
      position: relative;
      align-items: center;
      .logo-container {
        display: flex;
        align-items: center;
        .icon-logo {
          font-size: 30px;
          line-height: 0px;
          color: var(--color-heading);
        }
        .logo-text {
          font-size: 16px;
          font-family: var(--seconday-font-family);
          text-transform: uppercase;
          color: var(--color-heading);
        }
      }
      .property-name {
        color: var(--color-body);
        font-size: 16px;
        font-family: var(--seconday-font-family);
      }
      .address {
        color: var(--color-body);
        width: 225px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .property-list-item.active {
    border-radius: 16px;
    border: 1px solid var(--color-legend);
    background: var(--color-red-bg-primary);
  }

  .property-list-item .icon-checked::after {
    content: var(--icon-tik);
    color: var(--white);
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;
  }

  .header_secondary .modal-content-property {
    top: 100px;
    left: auto;
    right: var(--gutter);
  }

  .loading-container-property {
    overflow: hidden;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .loading-property::before {
    margin-left: calc(var(--gutter) / 2);
    height: 25px;
    width: 25px;
  }

  .property-img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 16px;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      content: '';
      background: white url('http://placehold.it/200x200');
    }
  }

  .icon-tick.selected {
    padding: 5px;
    color: var(--color-red-bg-primary);
    background-color: var(--color-green-primary);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .non-selected {
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-placeholder);
    border-radius: 50%;
  }
}
