.input-btn {
  text-align: center;
  border: 1px solid;
  padding: calc(var(--secondary-gutter) / 2) var(--secondary-gutter);
  border-radius: 8px;
  text-decoration: none;
  color: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &_primary {
    @extend .input-btn;
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }

  &_secondary {
    @extend .input-btn;
    background-color: transparent;
    border-color: var(--color-btn-border-secondary);
    color: var(--color-button-negative);
  }

  &:disabled::after {
    content: '';
    display: inline-block;
    border: 2px solid var(--white);
    border-color: var(--white) var(--white) transparent;
    border-radius: 50%;
    padding: 6px;
    margin-left: 8px;
    animation: spin 1s infinite linear;
  }
}
