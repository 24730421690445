.bottom-notification {
  padding: var(--gutter);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-notification);
  margin: 0 calc(var(--gutter) * -1);
  position: absolute;
  bottom: var(--footer-height);
  max-width: 100%;

  .icon-user-note {
    display: inline-flex;
    align-items: center;
    color: var(--color-heading);
    &::before {
      font-size: 24px;
      margin-right: var(--gutter);
      color: var(--color-icon-notification);
    }
  }
}
