.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: var(--black);
  margin-top: 24px;
  border-radius: 16px;
  background: var(--color-timer-bg);
  position: relative;

  .opening-img {
    min-height: 150px;
    aspect-ratio: 191/75;
    width: 100%;
  }
}

.show-counter .timer {
  display: flex;
  align-items: center;

  &-container {
    position: absolute;
    top: 18px;
    left: var(--gutter);
    right: var(--gutter);
  }

  &-desc {
    padding-top: calc(var(--gutter) / 2);
  }

  &-text {
    max-width: 60%;
    margin-top: 4px;
  }
}

.show-counter .logo {
  position: absolute;
  top: 10px;
  right: 12px;
  width: 67px;
}

.show-counter .show-counter .timer-heading {
  font-size: 22px;
  font-weight: var(--font-weight-boldest);
  line-height: 1.2;
}

.show-counter .countdown {
  border: 1px solid var(--white);
  border-radius: 8px;
  line-height: 1.25rem;
  padding: 5px 4px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 38px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.23) 57.81%,
    rgba(255, 255, 255, 0.83) 69.27%
  );

  ~ .seperator {
    padding: 0 5px;
  }

  &.danger {
    color: var(--black);
  }

  > p {
    margin: 0;
    font-size: var(--body-font-size);
    line-height: 1.2;
  }

  > span {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.2;
  }
}
