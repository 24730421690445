.textinput {
  display: flex;
  flex-direction: column;
  padding-top: var(--secondary-gutter);
  .error {
    border: 1px solid var(--color-error);
  }

  label {
    color: var(--color-heading);
    margin-bottom: calc(var(--gutter) / 2);
  }

  input {
    padding: 13px var(--gutter);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    background-color: var(--color-input-bg);
    color: var(--color-heading);

    &::placeholder {
      font-size: 14px;
    }

    &:disabled {
      color: var(--color-input-disabled);
    }
  }

  input[type='date'] {
    &::-webkit-inner-spin-button,
    ::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
}
