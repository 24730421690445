@import url('./font/webfont.css');
@import url('./typography.scss');
@import './icon.scss';

:root {
  /* Font variable  */
  --body-font-size: 16px;

  /* Font family */
  --seconday-font-family: 'Bally Thrill', 'Helvetica Neue';
  --primary-font-family: 'Inter', sans-serif;

  /* Font Weigth */
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-weight-bolder: 600;
  --font-weight-boldest: 700;

  /* COLORS */
  --color-heading-primary: #acaaaf;
  --color-heading: #e4e2e6;
  --color-body: #acaaaf;

  --black: #0c0313;
  --icon: #c1bdd0;
  --gray: #c1bdd0;
  --color-grid-button: #343438;
  --color-badge: #ffcb4f;
  --white: #ffffff;
  --shadow: rgba(0, 0, 0, 0.25);
  --header-primary-color: 'transparent';
  // linear-gradient(110.97deg, var(--color-primary) 50%, #af0000 100%);
  --listing-background-color: #1b1b1f;
  --listing-background-color-secondary: #131316;
  --primary-border-color: #303033;
  --disabled-btn-color: #908499;
  --primary-icon-backgrond-color: #59595a;
  --color-input-border: #cccccc;
  --color-input: #220000;
  --wave-color: rgba(236, 0, 0, 0.3);
  --color-popup-body: rgba(251, 248, 253, 0.7);
  --color-popup-icon-bg: linear-gradient(180deg, #3e3e46 0%, #242429 100%);
  --scan-area-color: #00ff0a;
  --color-modal-overlay: rgba(0, 0, 0, 0.5);
  --color-light-pink: #ffe9e9;
  --color-parking-discount: #15924a;
  --color-blur: #00000050;
  --color-border: #46464a;
  --color-blur: rgba(0, 0, 0, 0.4);
  --color-error: #ff897d;
  --color-gray-bg: rgba(0, 0, 0, 0.5);
  --color-button-negative: #ffb4a8;
  --background-notification: #690000;
  --color-pro: #feee00;
  --color-star: #84c450;
  --color-superstar: #00b6dd;
  --color-legend: #ef0502;
  --color-register-gradient-first: #3e285f;
  --color-register-gradient-second: #200833;
  --color-text: --color-body;
  --color-timer-bg: #efebed;
  --color-icon-games: #35d04e;
  --color-icon-offers: #feaa01;
  --color-icon-benefits: #35b4d0;
  --color-btn-gradient-primary: #29292e;
  --color-btn-gradient-secondary: #565661;
  --color-btn-border-secondary: #a08c89;
  --color-warning-icon: #f9b208;
  --color-blue: #358eff;
  --color-linear-line: linear-gradient(270deg, #c7c6ca 0.44%, rgba(199, 198, 202, 0) 100%);
  --color-rewards-heading: #e4e2e6;
  --color-rewards-white: #acaaaf;
  --color-primary: #ec0000;
  --color-input-focus: #c00100;
  --color-icon-notification: #ffdad4;
  --color-profile-bg: #0c0313;
  --color-profile-bg-primary: #1b1b1f;
  --color-pofile-border: rgba(255, 255, 255, 0.2);
  --box-profile-shadow: 0px;
  --color-border-coming-soon: #0c0313;
  --color-input-bg: #1f1f23;
  --color-icon-secondary-bg: linear-gradient(
    110deg,
    var(--color-btn-gradient-primary),
    var(--color-btn-gradient-secondary) 500px,
    var(--color-btn-gradient-primary) 800px
  );
  --color-bg-switch: #343438;
  --color-border-switch: #919094;
  --color-switch-active: #4a934a;
  --color-switch-bg: #cceecc;
  --color-popup-bg: #1b1b1f;
  --color-popup-secondary-bg: #292a2d;
  --color-experience-bottom-gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.82) 73.44%
  );
  --color-app-bg: #000000;
  --color-footer-bg: #000000;
  --color-popup-icon-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  --skelton-loader: linear-gradient(
    90deg,
    rgba(128, 89, 58, 0) 0,
    rgba(128, 89, 58, 0.4) 40%,
    rgba(128, 89, 58, 0.2) 60%,
    rgba(128, 89, 58, 0)
  );
  --label-color: #e4e2e6;
  /* CARDLESS */
  --color-balance-border: #39393c;
  --color-balance-bg: rgba(0, 0, 0, 0.5);
  --color-connecting: #0066ff;
  --color-connecting-stroke: rgba(255, 255, 255, 0.09);
  --color-connected-stroke: rgba(73, 219, 131, 0.3);
  --color-conneting-bg: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 109, 219, 0.25) 0%,
    rgba(73, 109, 219, 0) 100%
  );
  --color-disconnecting: #ef0502;
  --color-disconnecting-bg: #690000;
  --color-connected-bg: #053005;
  --color-connected: rgba(73, 219, 131, 0.3);
  --color-check-bg: #5d9252;
  --color-cardin: linear-gradient(135deg, #30f272 0%, #00831d 100%);
  --color-cardout: linear-gradient(135deg, #ff6c6c 0%, #a91a1a 100%),
    linear-gradient(135deg, #0083fd 0%, #1a28a9 100%),
    linear-gradient(135deg, #30f2c4 0%, #047b7b 100%);
  --color-connected-transparent-bg: rgba(0, 0, 0, 0.2);
  --color-tier-grdient: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 109, 219, 0.25) 0%,
    rgba(73, 109, 219, 0) 100%
  );
  --color-black: #000000;
  --color-text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15);
  --noise-bg-gradient: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.07) 0%,
    rgba(255, 255, 255, 0.14) 4.75%,
    rgba(255, 255, 255, 0.06) 14.25%,
    rgba(255, 255, 255, 0.07) 21.36%,
    rgba(255, 255, 255, 0.1) 28.65%,
    rgba(255, 255, 255, 0.13) 36.57%,
    rgba(255, 255, 255, 0.17) 47.96%,
    rgba(255, 255, 255, 0.13) 59.47%,
    rgba(255, 255, 255, 0.03) 79.95%,
    rgba(255, 255, 255, 0) 100%
  );
  --color-placeholder: #919094;
  --color-icon-bg: #39393c;
  --color-icon-border: #292a2d;
  --color-footer-border: #303034;
  --color-lobby-mid-box-bg: #0d0e11;
  --color-lobby-mid-box-border: #303034;
  --color-input-bg-disabled: #0d0e11;
  --color-green-primary: #4a934a;
  --color-red-bg-primary: #410000;
  --offers-bg: linear-gradient(259deg, #ef0502 0%, #690000 100%);
  --color-input-disabled: #919094;

  /* Size and spacing */
  --container-max-width: 450px;
  --gutter: 16px;
  --secondary-gutter: 24px;
  --header-height: 64px;
  --secondary-header-height: 110px;
  --footer-height: 70px;
  --notification-height: 58px;

  /* BREAKPOINTS */
  --mobile-max: 480px;
  --tablet-min: 768px;
  --tablet-max: 991px;
  --notebook-min: 992px;
  --notebook-max: 1023px;
}

/* Reset CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  font-style: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body {
  font-weight: var(--font-weight-normal);
  font-family: var(--primary-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background */
  font-size: var(--body-font-size);
  line-height: 1.6;
  color: var(--color-body);
  background-color: var(--color-app-bg);
}

input,
select,
textarea {
  font: inherit;
  line-height: inherit;
  color: var(--color-heading);
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
  /* Firefox */

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--color-heading);
  font-family: var(--seconday-font-family);
}

h1,
h2 {
  font-size: 22px;
  line-height: 23px;
  font-weight: var(--font-weight-boldest);
}

h3 {
  font-size: var(--body-font-size);
  line-height: 20px;
}

h4 {
  font-size: 14px;
  line-height: 20px;
}

:focus,
:focus-visible {
  outline: none;
}

.section-heading {
  font-size: 11px;
  line-height: 1.4;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.caption2 {
  font-size: 14px;
}

.small1 {
  font-size: 11px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

button[disabled],
.action-btn[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.action-btn[disabled] {
  opacity: 0.6;
}

.container {
  padding: 0 var(--gutter);
}

.section-gap {
  margin-bottom: var(--secondary-gutter);
}

.banner-section-gap {
  margin-bottom: 19px;
}

.is-hidden {
  display: none !important;
}

/* common action button class   */
.action-btn {
  font-size: 14px;
  width: 100%;
  background-color: var(--color-primary);
  border: 1px solid transparent;
  color: var(--white);
}

.action-btn.cancel {
  color: var(--color-heading);
  border-color: var(--color-heading);
  background-color: var(--white);
}

/* custom checkbox */
input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}

input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  background-color: var(--white);
}

input[type='checkbox']:checked:before {
  background-color: var(--color-primary);
}

input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 5px;
}

.text-left {
  text-align: left !important;
}

/* end */

@keyframes slideIn {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.submit-button-icon {
  height: 30px;
  padding: 6px;
  border-radius: 15px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.submit-button-icon::before {
  content: var(--icon-arrow-right);
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.modal {
  display: block;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--color-modal-overlay);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.parking {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.continue-action {
  bottom: 0px;
  left: 0;
  right: 0;
  padding: var(--gutter) 0px;
}

.loading::before {
  content: '';
  display: inline-block;
  border: 2px solid var(--color-primary);
  border-color: var(--color-primary) var(--color-primary) transparent;
  border-radius: 50%;
  padding: 6px;
  animation: spin 1s infinite linear;
}

.h-v-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

@import './theme.scss';
