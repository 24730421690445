@font-face {
    font-family: 'Bally Thrill';
    src: url('./BallyThrill_W_CdBd.woff2') format('woff2'), url('./BallyThrill_W_CdBd.woff') format('woff');
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: 'icon';
    /* src: url('./icon.eot?chmndq'); */
    src: url('./icon.ttf?chmndq') format('truetype'),
        url('./icon.woff?chmndq') format('woff'),
        url('./icon.svg?chmndq#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
