.disclaimer-content {
  padding: var(--gutter) 0 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  text-align: center;
  color: var(--color-heading);

  .disclaimer-discription {
    font-weight: var(--font-weight-normal);
    font-family: var(--primary-font-family);
    margin-bottom: var(--gutter);
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--color-primary);
  }

  img {
    max-width: 68px;
    max-height: 44px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
