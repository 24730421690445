.list-group {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: var(--listing-background-color);

    &:last-child {
      margin-bottom: 0;
    }

    .listing-link {
      .list-group & {
        border: 0;
        padding-left: 40px;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--color-bg-switch);
          -webkit-transition: 0.4s;
          transition: 0.4s;
          &:before {
            position: absolute;
            content: '';
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: var(--color-bg-switch);
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          /* Rounded sliders */
          &.round {
            border-radius: 34px;
            &:before {
              border-radius: 50%;
            }
          }
        }

        input:not(:checked) + .slider {
          background-color: var(--color-bg-switch);
          border: 2px solid var(--color-border-switch);
          &:before {
            height: 20px;
            width: 20px;
            left: 6px;
            bottom: 5px;
            background-color: var(--color-border-switch);
          }
        }

        input:checked + .slider {
          background-color: var(--color-switch-bg);
          &:before {
            background-color: var(--color-switch-active);
            transform: translateX(26px);
          }
        }
      }
    }
  }
}

.listing-link {
  font-family: var(--primary-font-family);
  min-height: 48px;
  flex-direction: row-reverse;
  padding: var(--gutter);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &:before {
    font-size: 12px;
  }

  &.listing-red {
    color: var(--color-primary);
    .icon-delete {
      color: var(--color-primary);
      &::before {
        color: var(--color-primary);
      }
    }
  }

  .coming-soon-badge {
    display: none;
    padding: 4px 8px;
    border-radius: 50px;
    border: 1px solid var(--color-border);
    font-size: 10px;
    line-height: 1.4;
    position: absolute;
    right: 32px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
    color: var(--gray);
  }

  &.coming-soon {
    pointer-events: none;

    .coming-soon-badge {
      display: inline-block;
    }
  }
  .icon-prefix {
    color: var(--color-heading-primary);
  }

  .icon-prefix::before {
    margin-right: var(--gutter);
    font-size: 28px;
    color: var(--color-body);
  }
}

.icon-minus {
  font-weight: var(--font-weight-bold);
}

.icon-trash::before {
  font-size: 12px;
  content: var(--icon-trash);
  color: var(--color-primary);
}
