.page-home {
  .balance-container {
    padding: 12px 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--color-balance-border);
    background-color: var(--color-balance-bg);

    .icon-coin {
      justify-content: center;
      display: flex;
      margin-right: 8px;
    }
    .balance-amount {
      margin-left: 8px;
      color: var(--color-heading);
      letter-spacing: 0.1px;
    }
  }

  .amt-container {
    display: flex;
    justify-content: space-between;
  }

  .middle-container {
    min-height: 108px;
    padding: 16px 16px;
    margin: 0px 8px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid var(--color-lobby-mid-box-border);
    border-top-color: transparent;
    background: var(--color-lobby-mid-box-bg);
    margin-bottom: var(--gutter);
    .section-gap {
      margin-bottom: 0px;
    }
  }

  .carousel-container {
    margin-top: var(--gutter);
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .lobbby-banner-gap {
    margin-bottom: 23px;
  }
}
