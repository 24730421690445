.reward-page {
  height: calc(100vh - var(--footer-height) - var(--header-height));
  .player-tier-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    position: relative;

    .tier-card-img {
      aspect-ratio: 382/242;
      max-width: 100%;
      min-height: 215px;

      &:not([src]) {
        background: var(--color-popup-bg);
        border-radius: 8px;
        width: 100%;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }

  .wel-heading {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .user-section {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: var(--color-primary);
    margin-bottom: 18px;
  }

  .player-detail {
    display: flex;
    align-items: center;

    .icon-arrow-right::after {
      font-weight: var(--font-weight-bold);
      font-size: 9px;
      border-radius: 50%;
      border: 2px solid;
      padding: 4px;
      margin-left: 0;
    }
  }

  .user-section .user-name {
    font-size: 24px;
    text-transform: uppercase;
    color: inherit;
    margin-right: 10px;
  }

  .user-section_learnmore {
    font-weight: var(--font-weight-bolder);
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
  }

  .tier-container {
    margin-top: var(--gutter);
    .tier-icon-button {
      padding: 20px 20px;
      display: flex;
      align-items: center;
      text-align: left;
      border: none;
      color: var(--color-heading);
      height: 90px;
      width: 100%;
      background: var(--color-gray-bg);
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      margin-bottom: 10px;
    }

    .tier-icon-button::before {
      color: var(--color-body);
      font-size: 49px;
      margin-right: 42px;
    }
  }

  .tier-title {
    line-height: 23px;
    margin-bottom: 4px;
  }

  .tier-subtitle {
    font-weight: var(--font-weight-bolder);
    font-size: 24px;
    line-height: 23px;
    text-transform: uppercase;
  }

  .learn-more {
    background-color: var(--color-gray-bg);
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    .icon-info {
      display: flex;
      align-items: center;
      color: var(--color-heading-primary);
      &::before {
        font-size: 24px;
        margin-right: calc(var(--gutter) / 2);
        color: var(--color-profile-text);
      }
    }
    .icon-arrow-head {
      display: flex;
      align-items: center;
      content: var(--icon-arrow-head);
    }
  }
}
