@import '../../mixin';

.icon-info {
  font-size: 16px;
  &::before {
    margin-right: 8px;
    color: var(--color-warning-icon);
  }
}

.pin-verification {
  .loading-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    &::before {
      padding: 30px;
    }
  }

  .verify-pin {
    .heading {
      margin-top: var(--secondary-gutter);
    }
    .sub-heading {
      margin-top: 8px;
      .active {
        color: var(--color-primary);
      }
    }
    .label-medium {
      color: var(--label-color);
      margin-top: var(--gutter);
    }
    .otp-container {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      &.heading {
        margin-top: 8px;
      }
    }
    .error-msg {
      @include error-msg();
    }
    .error-container {
      margin-top: var(--gutter);
      display: flex;
      align-items: center;
      .error-msg {
        margin: 0px;
      }
    }
    .input-btn_primary {
      margin-top: 24px;
    }
  }

  .heading-text {
    margin-bottom: 8px;
    margin-top: 19px;
  }

  .verification-form {
    margin-bottom: calc(var(--gutter) * 4);
    // overflow: auto;

    .userform-container {
      display: flex;
      flex-direction: column;
    }

    .continue-action {
      background-color: var(--black);
      position: fixed;
      bottom: 0;
      padding: var(--gutter);

      .action-btn {
        padding: 12px;
        border-radius: 8px;
      }
    }
  }
}
