.modal {
  &-content {
    position: fixed;
    bottom: 0;
    background-color: var(--color-popup-bg);
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: var(--gutter) var(--secondary-gutter) 30px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-body {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    img {
      padding-top: 38px;
    }

    .icon-modal {
      background: var(--color-popup-icon-bg);
      border-radius: 100px;
    }

    .error-icon-box {
      background: var(--color-popup-icon-bg);
      width: 104px;
      height: 104px;
      border-radius: 50%;

      .icon-modal-view {
        filter: var(--color-popup-icon-shadow);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 55px;
        color: var(--color-primary);
        background: center/contain no-repeat;
        overflow: hidden;
        width: 104px;
        height: 104px;
      }
    }

    .pop-sub-desc-section {
      background: var(--listing-background-color);
      border-radius: 4px;
      color: var(--color-heading);
      display: flex;
      flex-direction: column;
      padding: 17px 13px;
      margin-top: 16px;
    }

    .contact-container {
      background-color: var(--color-popup-secondary-bg);
      border-radius: 8px;
      padding: var(--gutter);
      margin-top: var(--gutter);

      .contact-info {
        display: flex;

        i {
          margin-right: 12px;
        }

        .contact-desc {
          margin-bottom: 14px;
        }
      }
    }

    > div {
      width: 100%;
      max-height: 300px;
      overflow: auto;
    }

    .sub-desc-text {
      font-size: 12px;
      line-height: 20px;
      color: var(--color-heading);
      display: flex;
      align-items: center;
    }

    .text-gap {
      margin-bottom: 12px;
    }

    .sub-desc-text::before {
      margin-right: 13px;
      color: var(--color-body);
      font-size: var(--body-font-size);
    }

    ul {
      display: list-item;
      padding-left: var(--gutter);
      margin-bottom: var(--gutter);
      margin-top: calc(var(--gutter) / 2);
    }

    em {
      font-style: italic;
    }
  }

  &-footer {
    color: var(--color-body);
    display: flex;
    justify-content: space-between;
  }

  .popup-header-text {
    font-family: var(--seconday-font-family);
    font-weight: var(--font-weight-bolder);
    font-size: 20px;
    line-height: 32px;
    color: var(--color-heading);
  }

  .popup-sub-header-text {
    color: var(--color-heading);
    margin-top: var(--gutter);
    text-align: center;
    padding: 0 5px;
  }

  .popup-desc-heading-text {
    font-size: 32px;
  }

  .popup-description-text {
    margin-top: 16px;
    text-align: center;
  }

  .positive-button {
    border-width: 0px;
  }

  .negative-button {
    margin-right: calc(var(--gutter) / 2);
  }

  .cross-button {
    border: none;
    background: none;
    display: flex;
    color: var(--color-rewards-white);
    font-size: var(--body-font-size);
    padding: 4px;

    &::before {
      transform: rotate(45deg);
    }
  }
}
