.footer {
  // backdrop-filter: blur(17px);
  background: var(--color-footer-bg);
  flex-basis: var(--footer-height);
  height: var(--footer-height);
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-footer-border);
  /* Bottom Nav Glass Effect */
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding-bottom: calc(var(--gutter) / 2);
  max-width: var(--container-max-width);

  &_nav {
    flex-grow: 1;
    bottom: 0;
    width: 100%;
    height: 55px;
    // box-shadow: 0 0 3px var(--color-border);
    display: flex;
    overflow-x: auto;
  }

  .nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: var(--primary-font-family);
    font-size: 11px;
    color: var(--icon);
    font-weight: var(--font-weight-normal);
    text-decoration: none;
    transition: 'background-color 0.1s ease-in-out';

    &.active {
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);
    }
  }

  .nav__icon::before {
    font-size: 19px;
    padding-bottom: 4px;
  }
}

.icon-home.active::before {
  content: '\e90c';
}

.icon-coin::before {
  content: '';
  width: 19px;
  height: 19px;
  background: url('~/src/assets/bally_buck_coin_inactive.svg') center/contain no-repeat;
}

.icon-coin.active::before {
  background: url('~/src/assets/bally_buck_coin.svg') center/contain no-repeat;
}

.visibility-hidden {
  &,
  & * {
    visibility: hidden;
    width: 0;
    height: 0;
    font-size: 0;
  }

  :before,
  :after {
    display: inline !important;
  }
}

.visibility-hidden::before,
.visibility-hidden::after {
  display: inline !important;
}

.footer .disabled {
  pointer-events: none;
  cursor: default;
}
