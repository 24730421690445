@import '../../../mixin';
.login-setpin {
  .otp-container {
    display: flex;
  }
  .input-heading {
    margin-top: 24px;
    margin-bottom: 8px;
    color: var(--color-heading);
  }
  .btn-continue {
    margin-top: 24px;
  }
  .error-msg {
    @include error-msg();
  }
}
