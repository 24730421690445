.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: var(--skelton-loader);
  animation: shimmer 1.5s infinite;
  content: '';
}

.skeleton {
  background-color: var(--color-app-bg);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 5px;
  min-height: 48px;
}
