@import '../../mixin';

.login-cognito {
  padding: calc(var(--gutter) / 2);
  background: url('~/public/assets/login-background.png') no-repeat transparent;

  .logo-section {
    .login-logo {
      margin-top: 67px;
      height: 64px;
      margin-bottom: 52px;
    }
  }

  .disclaimer-container {
    min-height: 100px;
    position: relative;
    flex: 1;

    .login-disclaimer {
      position: absolute;
      bottom: 0;
      text-align: center;
      left: 8px;
      right: 8px;
      font-size: 12px;
      color: var(--color-text);

      .underline {
        text-decoration: underline;
      }
    }
  }
}

.form-section {
  .form-heading {
    margin-bottom: 8px;
  }

  .form-sub-heading {
    i {
      font-style: italic;
    }
  }

  .skip-container {
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .skip-login {
      font-size: 14px;
      letter-spacing: 0.1px;
      color: var(--color-heading-primary);
    }
  }

  .email-input,
  .input-btn {
    margin-top: var(--secondary-gutter);
  }

  .error-msg {
    @include error-msg();
  }

  .seperator {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;

    &-line {
      height: 1px;
      flex: 1;
      background-color: #c7c6ca;
    }

    &-text {
      padding: 0px 14px;
    }
  }

  .input-btn_secondary {
    > i {
      color: var(--color-blue);
    }

    .face-id-text {
      margin-left: 8px;
      color: var(--color-heading-primary);
    }
  }
}

.icon-info {
  font-size: 16px;

  &::before {
    margin-right: 8px;
    color: var(--color-warning-icon);
  }
}

.verifyotp-cognito {
  .form-section {
    .form-heading {
      margin-top: 19px;
    }

    .form-sub-heading {
      margin-bottom: 40px;
    }

    .otp-container {
      display: flex;
      justify-content: space-between;
    }

    .error-container {
      margin-top: var(--secondary-gutter);
      display: flex;
    }

    .error-text {
      @include error-msg();
      color: var(--color-text);
    }

    .resend-text {
      margin-top: 16px;
      font-size: 14px;
      color: var(--color-text);
    }

    .resend-text.active {
      color: var(--color-primary);
    }

    .input-btn {
      margin-top: 24px;
    }

    .error-msg {
      @include error-msg();
      margin: 8px 0 0;
    }
  }
}

.verify-ssn {
  .heading-container {
    .heading-text {
      margin-bottom: 8px;
      margin-top: 19px;
    }
  }

  .input-box {
    position: relative;
    .input-masked-text {
      position: absolute;
      bottom: 0px;
      left: var(--gutter);
      padding: 12px 0px;
      letter-spacing: 0.1px;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .textinput {
      input {
        padding-left: 100px;
      }
    }
  }
  .error-msg {
    @include error-msg();
  }
  .continue-action {
    .action-btn {
      padding: 12px;
      border-radius: 8px;
    }
  }
}

.profile-verification {
  .loading-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    &::before {
      padding: 30px;
    }
  }

  .verify-pin {
    .heading {
      margin-top: 24px;
      line-height: 33.6px;
    }

    .sub-heading {
      margin-top: 8px;
      line-height: 22.4px;

      .active {
        color: var(--color-primary);
      }
    }

    .label-medium {
      color: var(--label-color);
      margin-top: 16px;
    }

    .otp-container {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      &.heading {
        margin-top: 8px;
      }
    }

    .error-msg {
      @include error-msg();
    }

    .error-container {
      margin-top: var(--gutter);
      display: flex;
      align-items: center;

      .error-msg {
        margin: 0px;
      }
    }

    .input-btn_primary {
      margin-top: 24px;
    }
  }

  .icon-back::before {
    content: var(--icon-arrow-head);
    transform: rotate(180deg);
    display: inline-block;
    margin-top: 40px;
    font-size: 20px;
    margin-left: var(--gutter);
    margin-bottom: 16px;
    // padding: 10px 0px 10px 10px;
  }

  .heading-text {
    margin-bottom: 8px;
    margin-top: 19px;
  }

  .verification-form {
    margin-bottom: calc(var(--gutter) * 4);
    // overflow: auto;

    .userform-container {
      display: flex;
      flex-direction: column;
    }

    .continue-action {
      background-color: var(--color-app-bg);
      position: fixed;
      bottom: 0;
      padding: var(--gutter);

      .action-btn {
        padding: 12px;
        border-radius: 8px;
      }
    }
  }
}
