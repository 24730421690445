.brand-logo {
  color: var(--color-legend);
  display: flex;
  align-items: center;

  .logo-text {
    text-transform: uppercase;
    font-family: var(--seconday-font-family);
    font-size: 20px;
  }

  &.horizontal {
    > i {
      font-size: 38px;
      line-height: 0;
      margin-right: 4px;
      max-height: 38px;
    }
  }

  &.vertical {
    flex-direction: column;

    > i {
      font-size: 55px;
      line-height: 30px;
      max-height: 45px;
    }

    .logo-text {
      text-indent: -12px;
      font-size: var(--body-font-size);
      line-height: 1;
    }
  }
}
