.container {
  .medium {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .medium input {
    width: calc(50vw - 55px);
  }

  input:disabled {
    opacity: 1;
    background: var(--color-input-bg-disabled);
  }
}
