.carousel-container {
  min-height: 190px;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-right: -15px;

  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 140px;
    margin-right: var(--gutter);

    &-details {
      width: 100%;
      padding-top: 12px;
    }

    &-media {
      aspect-ratio: 14/19;
      object-fit: cover;
      min-height: 190px;
      width: 100%;
      border-radius: 8px;
    }

    &-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      max-height: 32px;
      min-height: 32px;
      font-size: 12px;
      line-height: 16px;
      font-weight: var(--font-weight-normal);
      font-family: var(--primary-font-family), 'Helvetica Neue', sans-serif;
      -webkit-box-orient: vertical;
    }

    &-title {
      font-size: var(--body-font-size);
      line-height: 1.2;
      letter-spacing: 0.2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--color-rewards-heading);
    }
  }
}
