.tier-container-card {
  padding: 7px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  left: 0px;

  .progress-bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    padding: 7px;
    margin-right: 8px;
    border-radius: 50%;

    &::before {
      content: '';
      border-radius: 50%;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .tierBadges {
      width: 100%;
      height: 100%;
    }
  }

  .tier-details {
    flex-direction: column;

    .tier-heading {
      color: var(--color-rewards-white);
      font-size: 14px;
      font-weight: var(--font-weight-bold);
      line-height: 19.6px;
      margin-bottom: 4px;
    }

    p {
      color: var(--color-rewards-white);
    }

    .tier-value {
      color: var(--color-rewards-heading);
    }

    .tier-amt {
      color: var(--color-rewards-white);
      font-size: 14px;
      line-height: 19.6px;
    }

    .tier-total {
      opacity: 0.699999988079071;
    }
  }
}

.card-number {
  width: 100%;
  padding: 4px var(--gutter);
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 6px;
  text-align: center;
  color: var(--color-rewards-heading);
}
