.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: var(--header-height);
  max-height: var(--header-height);
  flex-basis: var(--header-height);
  padding: 10px var(--gutter);
  z-index: 1;
  background: var(--header-primary-color);
  transition: height 0.1s ease, max-height 0.1s ease;

  .header_title_user {
    color: var(--color-heading);
  }

  &.header_secondary {
    align-items: center;
    padding-top: var(--gutter);
    z-index: 1;
    overflow: hidden;
    color: var(--white);
    background: var(--header-primary-color);

    .header_title {
      margin-bottom: 8px;
      margin-top: 9px;
      line-height: 40px;
      color: inherit;
      height: 48px;
      font-size: 36px;
      text-transform: uppercase;
    }
  }

  &.header_tertiary {
    max-height: 0px;
  }

  .icon-arrow-right::after {
    margin-top: 4px;
    content: var(--icon-arrow-head);
    transform: rotate(90deg);
    display: inline-block;
    color: var(--white);
    margin-left: 10px;
  }

  .header-dropdown {
    display: flex;
    align-items: center;

    .icon-arrow-head::before {
      display: flex;
      margin-left: 8px;
      transform: rotate(90deg);
    }
  }

  .back-button-container {
    display: flex;
    color: var(--white);
    align-items: center;
    height: 40px;
    width: 40px;
  }

  .icon-arrow-head::before {
    font-size: 15px;
    transform: rotate(180deg);
    display: inline-block;
    color: var(--color-heading);
    font-weight: var(--font-weight-boldest);
  }

  .profile-container {
    border-radius: 50%;
    background-color: var(--color-profile-bg);
    border: 1px solid var(--color-pofile-border);
    box-shadow: var(--box-profile-shadow);
    height: 44px;
    width: 44px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hidden {
    width: 12%;
  }
}

.icon-users {
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 17px;
  border-radius: 100px;
  background: var(--color-heading);
  color: var(--white);

  &::before {
    content: var(--icon-user);
  }
}

.icon-coin.active::before {
  .balance-container & {
    display: inline-block;
    animation: coin-rotation 1.5s infinite linear;
  }
}

@keyframes coin-rotation {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}
