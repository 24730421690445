.skeleton {
  display: inline-block;
  min-height: 2em;
  position: relative;
  overflow: hidden;
  background-color: var(--gray);
  border-radius: 8px;

  &.grid-block {
    min-height: 48px;
    margin-bottom: 24px;
  }

  &.promo {
    min-height: 280px;
    width: 65vw;
    flex: 1 0 65vw;
    margin: calc(var(--gutter) / 2) 0;
    margin-right: calc(var(--gutter) / 2);
  }

  &.banner {
    border-radius: 0;
    aspect-ratio: 414/200;
    min-height: 200px;
    width: 100%;
  }

  &.banner-secondary {
    aspect-ratio: 414/152;
    min-height: 152px;
  }

  &.hotel_gallery {
    max-width: 100%;
    min-height: 260px;
    aspect-ratio: 382/260;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  &.restaurant {
    min-height: 133px;
    aspect-ratio: 382/133;
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  &.title {
    display: block;
    min-height: 40px;
    min-width: 200px;
  }

  &.profile-banner {
    aspect-ratio: 382/242;
    max-width: 500px;
    min-height: 215px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .page-reward & {
      margin-bottom: 20px;
    }
  }

  &.offer-banner {
    height: 56px;
    display: block;
  }

  &.reward-tier {
    height: 90px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  &.reward-card {
    height: 72px;
    width: 100%;
    border-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &.portait-tile {
    aspect-ratio: 278 / 425;
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--gray);
    margin-right: calc(var(--gutter) / 2);
  }

  &.portait-tile:last-child {
    margin-right: 0;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(255, 255, 255, 0.8) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1.5s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.offers-skeleton {
  overflow: hidden;
}

.loading-container {
  overflow: hidden;
  display: flex;
}

.portait-tile-row {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
}
