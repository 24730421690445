input.single-otpInput {
  outline: none;
  // font-size: 6px;
  color: var(--color-heading);
  flex: 1;
  width: 24px;
  height: 48px;
  padding-bottom: 5px;
  margin-right: var(--gutter);
  text-align: center;
  cursor: pointer;
  will-change: border;
  transition: border 0.3s ease-in-out;
  border-radius: 0;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  background-color: var(--color-input-bg);

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &.error {
    border-color: var(--color-error);
    box-shadow: 0 0 0 1px var(--color-error);
  }

  &:focus {
    border: 1px solid var(--color-input-focus);
    box-shadow: 0 0 0 1px var(--color-input-focus);
  }

  &:last-child {
    margin-right: 0;
  }
}
