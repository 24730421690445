$card-radius: 16px;

.card-profile {
  position: relative;
  aspect-ratio: 382/242;
  width: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center center;
  transition: transform 1s;

  &.is-flipped {
    transform: rotateY(-180deg);
  }

  .card__face {
    position: absolute;
    width: 100%;
    aspect-ratio: 382/242;
    backface-visibility: hidden;
    transform-origin: 50% 0;

    .player-tier-card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      border-radius: $card-radius;
      border: 2px solid var(--color-border);
      transition: all 0.4s ease;
      overflow: hidden;
      aspect-ratio: 382/242;
    }

    &.card__face--back {
      transform: rotateY(180deg);
      overflow: hidden;

      .player-tier-card {
        background: var(--noise-bg-gradient);
        background-color: var(--color-black);
        &.pro {
          border-color: var(--color-pro);
        }

        &.star {
          border-color: var(--color-star);
        }

        &.superstar {
          border-color: var(--color-superstar);
        }

        &.legend {
          border-color: var(--color-legend);
        }
        .box-connection {
          &.connecting {
            background: var(--color-conneting-bg);
          }
          &.disconnecting {
            background: var(--color-conneting-bg);
            background-color: var(--color-disconnecting-bg);
          }
          &.connected {
            background: var(--color-connected-bg);
          }
          z-index: 9;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $card-radius;
          flex-direction: column;
          .connection-status-text {
            position: absolute;
            bottom: 41px;
            color: var(--color-rewards-heading);
          }
          .icon-connection {
            color: white;
            padding: 12px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            display: flex;
            outline-offset: 5px;
            font-size: 34px;
            &.disconnecting {
              border: 2px solid var(--color-disconnecting);
              color: var(--color-disconnecting);
              animation: bounce 1s linear infinite;
            }
            &.connecting {
              border: 2px solid var(--color-connecting);
              color: var(--color-connecting);
              animation: bounce 1s linear infinite;
            }
            &.connected {
              background-color: var(--color-connected);
              animation: zoomout 1s linear none;
              // width: 0px;
              // height: 0px;
              // font-size: 0px;
            }
          }

          @keyframes bounce {
            0% {
              width: 57px;
              height: 57px;
              font-size: 34px;
            }
            50% {
              width: 65px;
              height: 65px;
              font-size: 44px;
            }
            100% {
              width: 57px;
              height: 57px;
              font-size: 34px;
            }
          }

          @keyframes zoomout {
            0% {
              width: 0px;
              height: 0px;
              font-size: 0px;
            }
            50% {
              width: 67px;
              height: 67px;
              font-size: 44px;
            }
            100% {
              width: 57px;
              height: 57px;
              font-size: 34px;
            }
          }

          span {
            width: 100%;
            height: 150%;
            position: absolute;
            box-sizing: border-box;
            border: 5px solid var(--color-connecting-stroke);
            &.connected {
              border: 5px solid var(--color-connected-stroke);
            }
            border-radius: 50%;
            opacity: 0;
            animation: animate 5s linear infinite;
            @for $i from 1 through 11 {
              &:nth-child(#{$i}n) {
                animation-delay: #{$i * 0.55}s;
              }
            }
          }

          @keyframes animate {
            0% {
              width: 75px;
              height: 75px;
              opacity: 1;
              border-width: 3px;
            }
            50% {
              opacity: 1;
              border-width: 2px;
            }
            100% {
              width: 100%;
              height: 150%;
              opacity: 0;
              border-width: 1px;
            }
          }
        }
      }
    }

    &.card__face--front {
      .player-tier-card {
        position: relative;
        aspect-ratio: 382/242;
        background: var(--noise-bg-gradient);
        background-color: var(--color-black);
        color: var(--color-pro);
        border-color: currentColor;
        &.pro {
          color: var(--color-pro);
        }

        &.star {
          color: var(--color-star);
        }

        &.superstar {
          color: var(--color-superstar);
        }

        &.legend {
          color: var(--color-legend);
        }

        &.connected {
          background-color: var(--color-connected-bg);
        }
        .tier-card-img {
          aspect-ratio: 382/242;
          width: 100%;
          border-radius: $card-radius;

          &[src=''] {
            aspect-ratio: 382/242;
            background: var(--color-popup-bg);
            width: 100%;
            border-radius: $card-radius;
            overflow: hidden;
            text-indent: -999px;
            visibility: hidden;
          }
        }
        .tier-level {
          position: absolute;
          right: 12px;
          top: 12px;
          text-transform: uppercase;
          font-weight: var(--font-weight-boldest);
          font-size: 18px;
          line-height: 1;
        }

        .cardin-container {
          .connected-check {
            position: absolute;
            right: 14px;
            bottom: 14px;
            padding: 10px 16px;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--color-connected-transparent-bg);
            color: var(--white);
            .icon-tick {
              color: var(--white);
              background-color: var(--color-check-bg);
              height: 28px;
              width: 28px;
              border-radius: 50%;
              justify-content: center;
              align-items: center;
              display: flex;
              margin-right: 8px;
            }
          }
          .cardout-btn {
            position: absolute;
            background: var(--color-cardout);
            border-radius: 50%;
            color: var(--white);
            display: flex;
            width: 48px;
            height: 48px;
            left: 14px;
            bottom: 14px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            .cardin-text {
              font-size: 8px;
              color: var(--white);
              text-shadow: var(--color-text-shadow);
            }
            .icon-card-out {
              line-height: 1;
            }
          }
          .cardin-btn {
            position: absolute;
            bottom: 14px;
            right: 14px;
            padding: 12px 20px;
            background: var(--color-cardin);
            border-radius: 50px;
            min-width: 136px;
            display: flex;
            justify-content: center;
            color: var(--white);
            align-items: center;

            .cardin-text {
              margin-left: 8px;
              color: var(--white);
              text-shadow: var(--color-text-shadow);
              line-height: 140%;
              letter-spacing: 0.1px;
            }
          }
        }

        .login-text {
          bottom: 71px;
          position: absolute;
          color: var(--color-rewards-heading);
        }
      }
    }
  }
}
