.display,
.headline,
.title {
  &-large,
  &-medium,
  &-small {
    font-family: var(--seconday-font-family);
    font-weight: var(--font-weight-boldest);
    line-height: 1.2;
  }
}

.display {
  &-large {
    font-size: 57px;
  }

  &-medium {
    font-size: 45px;
  }

  &-small {
    font-size: 36px;
  }
}

.headline {
  &-large {
    font-size: 32px;
  }

  &-medium {
    font-size: 28px;
  }

  &-small {
    font-size: 24px;
  }
}

.title {
  &-large {
    font-size: 22px;
  }

  &-medium {
    font-size: 16px;
  }

  &-small {
    font-size: 14px;
  }
}

.body-text {
  font-weight: var(--font-weight-normal);
  line-height: 1.6;

  &-large,
  &-medium,
  &-small {
    @extend .body-text;

    &.prominant {
      font-weight: var(--font-weight-bold);
    }
  }

  &-large {
    font-size: 16px;
  }

  &-medium {
    font-size: 14px;
  }

  &-small {
    font-size: 12px;
  }
}

.label {
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  letter-spacing: 0.1px;

  &-large,
  &-medium,
  &-small {
    @extend .label;

    &.prominant {
      font-weight: var(--font-weight-bolder);
    }
  }

  &-large {
    font-size: 16px;
  }

  &-medium {
    font-size: 14px;
  }

  &-small {
    font-size: 12px;
  }
}
