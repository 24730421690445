.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  gap: 8px;

  .grid-item {
    margin: 0 auto;
  }

  &.secondary {
    display: flex;
    justify-content: center;

    .grid-icon {
      flex: 0 0 percentage(1/3);
      width: percentage(1/3);
    }
  }
}
